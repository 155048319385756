import MD5 from 'crypto-js/md5';
import SHA256 from 'crypto-js/sha256';


document.querySelectorAll('[data-skaze-click]').forEach((element) => {
  element.addEventListener('click', handleClick);
});


function handleClick(e) {
  if (window.skaze) {
    let plop = JSON.parse(e.currentTarget.dataset.skazeProperties);
    let enventName = e.currentTarget.dataset.skazeClick;

    window.skaze.pushEvent({
      name: enventName,
      properties: plop
    });

  }
}

// Detect Lead
if (document.querySelector(".page-template-landing .s-crm-form")) {
  let leadID= document.querySelector(".page-template-landing article").getAttribute("data-lead-id");

  // get the values befor submit
  const parent = document.querySelector(".page-template-landing .s-crm-form");
  parent.addEventListener('submit', handleSubmitClick);

  let valueCountry;
  let valuePhone;
  let valueEmail;

  //let submitinprogress = false;
  let parentMutation;

  function handleSubmitClick(e) {
    e.preventDefault();
    valueCountry = document.querySelector('.s-crm-form .s-crm-form input[id="dd65767b-21eb-ec11-bb3d-00224882c631"]').value;

    valuePhone = document.querySelector('.s-crm-form .s-crm-form input[type="tel"]').value;
    valueEmail = document.querySelector('.s-crm-form .s-crm-form input[type="email"]').value;

    parentMutation = document.querySelector(".s-crm-form .s-crm-form");
    const config = {
      childList: true,
      attributes: true,
      characterData: true
    };
    const observer = new MutationObserver(handler);
    observer.observe(parentMutation, config);
  }

  /* Callback function when mutation happens */
  function handler(mutationRecords, observer) {
    mutationRecords.forEach((mutationRecord) => {
      if(mutationRecord.type == "childList" && mutationRecord.addedNodes.length == 1) {
        if (window.skaze) {
          window.skaze.pushEvent({
            name: "essca - Lead Brochure",
            properties: {
              country: valueCountry,
              phoneSHA256: SHA256(valuePhone),
              phoneMD5: MD5(valuePhone),
              leadId: leadID,
              emailSHA256: SHA256(valueEmail),
              emailMD5: MD5(valueEmail),
            }
          });
        }
      }
    });
  }
}
